import * as fromRouter from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import * as fromAuth from './auth.reducer';
import * as fromBlock from './block.reducer';
import * as fromConvention from './convention.reducer';
import * as fromGlossary from './glossary.reducer';
import * as fromListing from './listing.reducer';
import * as fromProduct from './product.reducer';
import * as fromRegistration from './registration.reducer';
import * as fromRole from './role.reducer';
import * as fromUser from './user.reducer';
import * as fromExchange from './exchange.reducer';
import * as fromJobAdvertisement from './job-advertisement.reducer';

export interface AppState {
  router: fromRouter.RouterReducerState<any>;
  block: fromBlock.BlockState,
  auth: fromAuth.AuthState;
  listing: fromListing.ListingState,
  role: fromRole.RoleState;
  user: fromUser.UserState;
  glossary: fromGlossary.GlossaryState;
  product: fromProduct.ProductState;
  convention: fromConvention.ConventionState;
  registration: fromRegistration.RegistrationState;
  exchange: fromExchange.ExchangeState;
  jobAdvertisement: fromJobAdvertisement.JobAdvertisementState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: fromRouter.routerReducer,
  block: fromBlock.reducer,
  auth: fromAuth.reducer,
  listing: fromListing.reducer,
  role: fromRole.reducer,
  user: fromUser.reducer,
  glossary: fromGlossary.reducer,
  product: fromProduct.reducer,
  convention: fromConvention.reducer,
  registration: fromRegistration.reducer,
  exchange: fromExchange.reducer,
  jobAdvertisement: fromJobAdvertisement.reducer
};


export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: [{ auth: ['token'] }], rehydrate: true })(reducer);
}
export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, fromAuth.clearState];

export const selectRouter = createFeatureSelector<AppState, fromRouter.RouterReducerState<any>>('router');
export const selectAuthState = createFeatureSelector<fromAuth.AuthState>('auth');
export const selectBlockState = createFeatureSelector<fromBlock.BlockState>('block');
export const selectListingState = createFeatureSelector<fromListing.ListingState>('listing');
export const selectRoleState = createFeatureSelector<fromRole.RoleState>('role');
export const selectUserState = createFeatureSelector<fromUser.UserState>('user');
export const selectGlossaryState = createFeatureSelector<fromGlossary.GlossaryState>('glossary');
export const selectProductState = createFeatureSelector<fromProduct.ProductState>('product');
export const selectConventionState = createFeatureSelector<fromConvention.ConventionState>('convention');
export const selectRegistrationState = createFeatureSelector<fromRegistration.RegistrationState>('registration');
export const selectExchangeState = createFeatureSelector<fromExchange.ExchangeState>('exchange');
export const selectJobAdvertisementState = createFeatureSelector<fromJobAdvertisement.JobAdvertisementState>('jobAdvertisement');

//Route selectors
const {
  selectQueryParams,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteParams,    // select the current route params
  selectRouteParam,     // factory function to select a route param
  selectRouteData,      // select the current route data
  selectUrl,            // select the current url
} = fromRouter.getSelectors(selectRouter);

export const selectRouteId = selectRouteParam('id');
export const selectStatus = selectQueryParam('status');
