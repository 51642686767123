import { createAction, props } from '@ngrx/store';

import { User, UserDTO } from '../../commons/models/user.model';
import { UserFilters } from './../../commons/models/user.model';

export const loadUsers = createAction('[Users] Load users', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: UserFilters }>());
export const loadUsersCompleted = createAction('[Users] Load users Completed', props<{ users: UserDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: UserFilters }>());
export const loadUsersFailed = createAction('[Users] Load users Failed', props<{ error: any }>());

export const loadCompanyUsers = createAction('[Users] Load company users');
export const loadCompanyUsersCompleted = createAction('[Users] Load company users Completed', props<{ companyUsers: UserDTO[]}>());
export const loadCompanyUsersFailed = createAction('[Users] Load company users Failed', props<{ error: any }>());


export const changePage = createAction('[Users] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Users] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Users] Change filters', props<{ filters: UserFilters }>());

export const editUser = createAction('[Users] Edit user', props<{ user: User }>());
export const userDialogOpened = createAction('[Users] Detail dialog opened', props<{ dialogId: string }>());
export const closeUserDialog = createAction('[Users] Close detail dialog');

export const saveUser = createAction('[Users] Save user', props<{ user: User }>());
export const saveUserCompleted = createAction('[Users] Save user completed', props<{ user: UserDTO }>());
export const saveUserFailed = createAction('[Users] Save user failed', props<{ error: any }>());

export const deleteUser = createAction('[Users] Delete user', props<{ user: User }>());
export const deleteUserCompleted = createAction('[Users] Delete user completed', props<{ user: UserDTO }>());
export const deleteUserCancelled = createAction('[Users] Delete user cancelled');
export const deleteUserFailed = createAction('[Users] Delete user failed', props<{ error: any }>());

export const approveUser = createAction('[Users] Approve user', props<{ user: UserDTO }>());
export const approveUserCompleted = createAction('[Users] Approve user completed', props<{ user: UserDTO }>());
export const approveUserCancelled = createAction('[Users] Approve user cancelled');
export const approveUserFailed = createAction('[Users] Approve user failed', props<{ error: any }>());

export const disapproveUser = createAction('[Users] Disapprove user', props<{ user: UserDTO }>());
export const disapproveUserCompleted = createAction('[Users] Disapprove user completed', props<{ user: UserDTO }>());
export const disapproveUserCancelled = createAction('[Users] DisaApprove user cancelled');
export const disapproveUserFailed = createAction('[Users] Disaapprove user failed', props<{ error: any }>());

export const changeProfileImage = createAction('[Users] Change profile image', props<{ user: UserDTO, image: any }>());
export const changeProfileImageCompleted = createAction('[Users] Change profile image completed', props<{ user: UserDTO }>());
export const changeProfileImageFailed = createAction('[Users] Change profile image failed', props<{ error: any }>());

export const clearProfileImage = createAction('[Users] Clear profile image', props<{ user: UserDTO }>());
export const clearProfileImageCompleted = createAction('[Users] Clear profile image completed', props<{ user: UserDTO }>());
export const clearProfileImageFailed = createAction('[Users] Clear profile image failed', props<{ error: any }>());

export const exportUsers = createAction('[Users] Export users', props<{ filters?: UserFilters }>());
export const exportUsersCompleted = createAction('[Users] Export users completed', props<{ file: Blob }>());
export const exportUsersFailed = createAction('[Users] Export users failed', props<{ error: any }>());

export const openExchanges = createAction('[Users] Open user Exchanges', props<{ user: User }>());
export const userExchangeDialogOpened = createAction('[Users] Exchange dialog opened', props<{ userExchangeDialogId: string }>());
export const closeUserExchangeDialog = createAction('[Users] Close exchange dialog');
