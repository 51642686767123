import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { EditorModule } from "@tinymce/tinymce-angular";

import { MaterialModule } from './../material/material.module';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ImgFallbackDirective } from './directives/img-fallback.directive';
import { HtmlEditorComponent } from './html-editor/html-editor.component';
import { TinyHtmlEditorComponent } from './tiny-html-editor/tiny-html-editor.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';

@NgModule({
  imports: [
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    FroalaViewModule,
    FroalaEditorModule,
    EditorModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    ImgFallbackDirective,
    HtmlEditorComponent,
    TinyHtmlEditorComponent,
    ImageUploadComponent
  ],
  declarations: [
    ConfirmDialogComponent,
    ChangePasswordDialogComponent,
    ImgFallbackDirective,
    HtmlEditorComponent,
    ImageUploadComponent,
    TinyHtmlEditorComponent
  ],
  entryComponents: [ConfirmDialogComponent, ChangePasswordDialogComponent]
})
export class SharedModule { }
