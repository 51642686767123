import { Action, createReducer, on } from '@ngrx/store';

import { JobAdvertisementDTO } from '../../commons/models/job-advertisement.model';
import * as JobAdvertisementActions from '../actions/job-advertisement.actions';
import { JobAdvertisementFilters } from '../../commons/models/job-advertisement.model';

export interface JobAdvertisementState {
  list: JobAdvertisementDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  dialogId: string,
  jobApplicationDialogId: string,
  filters: JobAdvertisementFilters
};

const initialState: JobAdvertisementState = {
  list: [],
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  dialogId: null,
  filters: null,
  jobApplicationDialogId: null
};

const JobAdvertisementReducer = createReducer(
  initialState,
  on(JobAdvertisementActions.loadJobAdvertisementsCompleted, (state, { JobAdvertisements, currentPage, total, perPage, order, direction, filters }) => {
    return { ...state, list: JobAdvertisements, currentPage, total, perPage, order, direction, filters };
  }),
  on(JobAdvertisementActions.JobAdvertisementDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(JobAdvertisementActions.JobApplicationsDialogOpened, (state, { dialogId }) => {
    return { ...state, jobApplicationDialogId: dialogId };
  }),
  on(JobAdvertisementActions.changeFilters, (state, { filters }) => {
    return { ...state, filters };
  })
);

export function reducer(state: JobAdvertisementState | undefined, action: Action) {
  return JobAdvertisementReducer(state, action);
}

