  <editor
  [(ngModel)]="value"
  apiKey="w9pjd24we2ezqsjye0es1aszfn2m5qgup6cjq1j6znh9gnef"
  [init]="{
    icons: 'material',
    skin: 'borderless',
    plugins: 'wordcount',
    menubar: false,
    min_height: 150
  }"
></editor>
<div [hidden]="true">
  <div class="glossary_term" *ngFor="let term of glossaryTerms" [attr.data-term-id]="term.id"
    [attr.data-term-word]="term.word">{{term.word}}</div>
</div>
