import { createAction, props } from '@ngrx/store';

import { JobAdvertisement, JobAdvertisementDTO, JobAdvertisementFilters } from '../../commons/models/job-advertisement.model';

export const loadJobAdvertisements = createAction('[JobAdvertisement] Load JobAdvertisements', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: JobAdvertisementFilters }>());
export const loadJobAdvertisementsCompleted = createAction('[JobAdvertisement] Load JobAdvertisements Completed', props<{ JobAdvertisements: JobAdvertisementDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: JobAdvertisementFilters }>());
export const loadJobAdvertisementsFailed = createAction('[JobAdvertisement] Load JobAdvertisements Failed', props<{ error: any }>());

export const changePage = createAction('[JobAdvertisement] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[JobAdvertisement] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[JobAdvertisement] Change filters', props<{ filters: JobAdvertisementFilters }>());

export const editJobAdvertisement = createAction('[JobAdvertisement] Edit JobAdvertisement', props<{ JobAdvertisement: JobAdvertisement }>());
export const showJobApplications = createAction('[JobAdvertisement] Show JobApplications', props<{ JobAdvertisement: JobAdvertisement }>());
export const addJobAdvertisement = createAction('[JobAdvertisement] Add JobAdvertisement');
export const JobAdvertisementDialogOpened = createAction('[JobAdvertisement] Detail dialog opened', props<{ dialogId: string }>());
export const closeJobAdvertisementDialog = createAction('[JobAdvertisement] Close detail dialog');
export const JobApplicationsDialogOpened = createAction('[JobAdvertisement] Job Applications dialog opened', props<{ dialogId: string }>());
export const closeJobApplicationsDialog = createAction('[JobAdvertisement] Close Job Applications dialog');

export const saveJobAdvertisement = createAction('[JobAdvertisement] Save JobAdvertisement', props<{ JobAdvertisement: JobAdvertisement }>());
export const saveJobAdvertisementCompleted = createAction('[JobAdvertisement] Save JobAdvertisement completed', props<{ JobAdvertisement: JobAdvertisementDTO }>());
export const saveJobAdvertisementFailed = createAction('[JobAdvertisement] Save JobAdvertisement failed', props<{ error: any }>());

export const approveJobAdvertisement = createAction('[JobAdvertisements] Approve JobAdvertisement', props<{ JobAdvertisement: JobAdvertisementDTO }>());
export const approveJobAdvertisementCompleted = createAction('[JobAdvertisements] Approve JobAdvertisement completed', props<{ JobAdvertisement: JobAdvertisementDTO }>());
export const approveJobAdvertisementCancelled = createAction('[JobAdvertisements] Approve JobAdvertisement cancelled');
export const approveJobAdvertisementFailed = createAction('[JobAdvertisements] Approve JobAdvertisement failed', props<{ error: any }>());

export const disapproveJobAdvertisement = createAction('[JobAdvertisements] Disapprove JobAdvertisement', props<{ JobAdvertisement: JobAdvertisementDTO }>());
export const disapproveJobAdvertisementCompleted = createAction('[JobAdvertisements] Disapprove JobAdvertisement completed', props<{ JobAdvertisement: JobAdvertisementDTO }>());
export const disapproveJobAdvertisementCancelled = createAction('[JobAdvertisements] DisaApprove JobAdvertisement cancelled');
export const disapproveJobAdvertisementFailed = createAction('[JobAdvertisements] Disaapprove JobAdvertisement failed', props<{ error: any }>());

export const deleteJobAdvertisement = createAction('[JobAdvertisement] Delete JobAdvertisement', props<{ JobAdvertisement: JobAdvertisement }>());
export const deleteJobAdvertisementCompleted = createAction('[JobAdvertisement] Delete JobAdvertisement completed', props<{ JobAdvertisement: JobAdvertisementDTO }>());
export const deleteJobAdvertisementCancelled = createAction('[JobAdvertisement] Delete JobAdvertisement cancelled');
export const deleteJobAdvertisementFailed = createAction('[JobAdvertisement] Delete JobAdvertisement failed', props<{ error: any }>());

export const exportJobApplications = createAction('[JobApplications] Export JobApplications', props<{ jobAdvertisement: JobAdvertisement }>());
export const exportJobApplicationsCompleted = createAction('[JobApplications] Export JobApplications completed', props<{ file: Blob }>());
export const exportJobApplicationsFailed = createAction('[JobApplications] Export JobApplications failed', props<{ error: any }>());