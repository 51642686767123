import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ListResultDTO } from "src/app/helpers/listResult.interface";
import { environment } from "src/environments/environment";

import { UserDTO, UserFilters } from "../../models/user.model";

export interface LoginSuccessDTO {
  access_token: string;
  user: UserDTO;
  expires_at: Date;
  token_type: string;
}

export interface SimpleResponseDTO {
  success: boolean;
  message: string;
}

@Injectable({
  providedIn: "root",
})
export class LaravelUserService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      currentUser: environment.baseUrl + "/api/auth/user",
      login: environment.baseUrl + "/api/auth/login",
      logout: environment.baseUrl + "/api/auth/logout",
      list: environment.baseUrl + "/api/users",
      store: environment.baseUrl + "/api/users",
      approve: environment.baseUrl + "/api/approveUser",
      delete: environment.baseUrl + "/api/users",
      changePassword: environment.baseUrl + "/api/changePassword",
      changeProfileImage: environment.baseUrl + "/api/changeProfileImage",
      export: environment.baseUrl + "/api/users/export",
    };
  }

  public list(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filters?: UserFilters
  ): Observable<ListResultDTO<UserDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;

    if (filters) {
      if (filters.search) params["search"] = filters.search;
      if (filters.approved != undefined) {
        if (filters.approved == "isProposed") {
          params["approved"] = true;
          params["is_proposed"] = true;
        } else {
          params["approved"] = filters.approved;
          params["is_proposed"] = false;
        }
      }
      if (filters.visible != undefined) params["visible"] = filters.visible;
      if (filters.newsletter_enabled != undefined)
        params["newsletter_enabled"] = filters.newsletter_enabled;
      if (filters.partner_communications_enabled != undefined)
        params["partner_communications_enabled"] =
          filters.partner_communications_enabled;
      if (filters.profession_id)
        params["profession_id"] = filters.profession_id;
      if (filters.specialization_id)
        params["specialization_id"] = filters.specialization_id;
      if(filters.role_id) {
        params["role_id"] = filters.role_id;
      }  
      if(filters.role_name) {
        params["role_name"] = filters.role_name;
      }
    }
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
      return this.httpClient.get<ListResultDTO<UserDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params }),
      });
    } else {
      return this.httpClient
        .get<UserDTO[]>(this.ROUTES.list, {
          params: new HttpParams({ fromObject: params }),
        })
        .pipe(
          map((results) => {
            return {
              data: results,
              total: results.length,
            };
          })
        );
    }
  }

  public getCurrentUser(): Observable<UserDTO> {
    return this.httpClient.get<UserDTO>(this.ROUTES.currentUser);
  }

  public upsert(user: UserDTO): Observable<UserDTO> {
    return user.id ? this.update(user) : this.create(user);
  }

  public create(user: UserDTO): Observable<UserDTO> {
    return this.httpClient.post<UserDTO>(`${this.ROUTES.store}`, user);
  }

  public update(user: UserDTO): Observable<UserDTO> {
    return this.httpClient.put<UserDTO>(
      `${this.ROUTES.store}/${user.id}`,
      user
    );
  }

  public login(email: string, password: string): Observable<LoginSuccessDTO> {
    return this.httpClient.post<LoginSuccessDTO>(this.ROUTES.login, {
      email,
      password,
    });
  }

  public logout(): Observable<any> {
    return this.httpClient.post<any>(this.ROUTES.logout, {});
  }

  public changePassword(
    userId: number,
    new_password: string
  ): Observable<UserDTO> {
    return this.httpClient.put<UserDTO>(
      `${this.ROUTES.changePassword}/${userId}`,
      { new_password }
    );
  }

  public approveUser(
    userId: number,
    approved: boolean = true
  ): Observable<UserDTO> {
    return this.httpClient.put<UserDTO>(`${this.ROUTES.approve}/${userId}`, {
      approved,
    });
  }

  public delete(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.ROUTES.delete}/${id}`);
  }

  public changeProfileImage(
    userId: number,
    image: any
  ): Observable<SimpleResponseDTO> {
    let formData: FormData = new FormData();
    formData.append("image", image);
    return this.httpClient.post<SimpleResponseDTO>(
      `${this.ROUTES.changeProfileImage}/${userId}`,
      formData
    );
  }

  public clearProfileImage(userId: number): Observable<SimpleResponseDTO> {
    return this.httpClient.post<SimpleResponseDTO>(
      `${this.ROUTES.changeProfileImage}/${userId}`,
      { delete_profile_image: 1 }
    );
  }

  public export(filters?: UserFilters): Observable<Blob> {
    let params = {};
    if (filters) {
      if (filters.search) params["search"] = filters.search;
      if (filters.approved != undefined) {
        if (filters.approved == "isProposed") {
          params["approved"] = true;
          params["is_proposed"] = true;
        } else {
          params["approved"] = filters.approved;
          params["is_proposed"] = false;
        }
      }
      if (filters.visible != undefined) params["visible"] = filters.visible;
      if (filters.newsletter_enabled != undefined)
        params["newsletter_enabled"] = filters.newsletter_enabled;
      if (filters.partner_communications_enabled != undefined)
        params["partner_communications_enabled"] =
          filters.partner_communications_enabled;
      if (filters.profession_id != undefined)
        params["profession_id"] = filters.profession_id;
      if (filters.specialization_id != undefined)
        params["specialization_id"] = filters.specialization_id;
    }
    return this.httpClient.get<Blob>(this.ROUTES.export, {
      params: new HttpParams({ fromObject: params }),
      responseType: "blob" as "json",
    });
  }
}
