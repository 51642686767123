import { exchangeDialogOpened } from './../actions/exchange.actions';
import { Action, createReducer, on } from '@ngrx/store';

import { UserDTO, UserFilters } from '../../commons/models/user.model';
import * as UserActions from '../actions/user.actions';

export interface UserState {
  list: UserDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: UserFilters,
  dialogId: string,
  userExchangeDialogId: string,
  companyUsers: UserDTO[],
};

const initialState: UserState = {
  list: [],
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  userExchangeDialogId: null,
  companyUsers: []
};

const userReducer = createReducer(
  initialState,
  on(UserActions.loadUsersCompleted, (state, { users, currentPage, total, perPage, order, direction, filters }) => {
    return { ...state, list: users, currentPage, total, perPage, order, direction, filters };
  }),
  on(UserActions.loadCompanyUsersCompleted, (state, { companyUsers }) => {
    return { ...state, companyUsers };
  }),
  on(UserActions.userDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(UserActions.userExchangeDialogOpened, (state, { userExchangeDialogId }) => {
    return { ...state, userExchangeDialogId };
  }),
  on(UserActions.changeFilters, (state, { filters }) => {
    return { ...state, filters };
  })
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}

