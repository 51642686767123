import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';

interface ListingDTO extends BaseDTO {
  value: string;
}

export class Listing extends Base {
  value: string;
  constructor(dto: ListingDTO) {
    super(dto);
    if (dto) {
      this.value = dto.value
    }
  }

  toDTO(): ListingDTO {
    let dto: ListingDTO = <ListingDTO>super.toDTO();
    dto.value = this.value;
    return dto;
  }

  public static compare(b1: Listing, b2: Listing): boolean {
    return b1 && b2 ? b1.value === b2.value : b1 === b2;
  }

  static fromValue(value: string): Listing {
    let entity = new Listing(null);
    entity.value = value;
    return entity;
  }
}

export interface DepartmentDTO extends ListingDTO { }

export class Department extends Listing {
  constructor(dto: DepartmentDTO) {
    super(dto);
  }

  toDTO(): DepartmentDTO {
    return <DepartmentDTO>super.toDTO();
  }
}

export interface EmployerDTO extends ListingDTO {
  is_public: boolean
}

export class Employer extends Listing {
  public: boolean;
  constructor(dto: EmployerDTO) {
    super(dto);
    if (dto) {
      this.public = dto.is_public;
    }
  }

  toDTO(): EmployerDTO {
    let dto = <EmployerDTO>super.toDTO();
    dto.is_public = this.public;
    return dto;
  }
}

export interface ExerciseFormDTO extends ListingDTO { }

export class ExerciseForm extends Listing {
  constructor(dto: ExerciseFormDTO) {
    super(dto);
  }

  toDTO(): ExerciseFormDTO {
    return <ExerciseFormDTO>super.toDTO();
  }
}

export interface ExperienceDTO extends ListingDTO { }

export class Experience extends Listing {
  constructor(dto: ExperienceDTO) {
    super(dto);
  }

  toDTO(): ExperienceDTO {
    return <ExperienceDTO>super.toDTO();
  }
}

export interface ProductBrandDTO extends ListingDTO { }

export class ProductBrand extends Listing {
  constructor(dto: ProductBrandDTO) {
    super(dto);
  }

  toDTO(): ProductBrandDTO {
    return <ProductBrandDTO>super.toDTO();
  }
}

export interface ProductCategoryDTO extends BaseDTO {
  name: string,
  features?: string,
  action?: string,
  indications?: string,
  contraindications?: string,
  advantages?: string,
  disadvantages?: string,
  parent_id?: number,
  parent?: ProductCategoryDTO
}

export class ProductCategory extends Base {
  name: string;
  features?: string;
  action?: string;
  indications?: string;
  contraindications?: string;
  advantages?: string;
  disadvantages?: string;
  parent?: ProductCategory

  constructor(dto: ProductCategoryDTO) {
    super(dto);
    if (dto) {
      this.name = dto.name;
      this.features = dto.features;
      this.action = dto.action;
      this.indications = dto.indications;
      this.contraindications = dto.contraindications;
      this.advantages = dto.advantages;
      this.disadvantages = dto.disadvantages;
      if (dto.parent) {
        this.parent = new ProductCategory(dto.parent);
        this.loadedRelations.push("parent");
      }
    }
  }

  toDTO(): ProductCategoryDTO {
    let dto: ProductCategoryDTO = <ProductCategoryDTO>super.toDTO();
    dto.name = this.name;
    dto.features = this.features;
    dto.action = this.action;
    dto.indications = this.indications;
    dto.contraindications = this.contraindications;
    dto.advantages = this.advantages;
    dto.disadvantages = this.disadvantages;
    dto.parent = this.parent ? this.parent.toDTO() : null;
    dto.parent_id = dto.parent ? dto.parent.id : null;
    return dto;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: ProductCategory) {
    const formModel = formGroup.value;
    let productCategory: ProductCategory = new ProductCategory(null);
    productCategory.name = formModel.name;
    productCategory.features = formModel.features;
    productCategory.action = formModel.action;
    productCategory.indications = formModel.indications;
    productCategory.contraindications = formModel.contraindications;
    productCategory.advantages = formModel.advantages;
    productCategory.disadvantages = formModel.disadvantages;
    productCategory.parent = formModel.parent;
    if (original) {
      productCategory.id = original.id;
    }
    return productCategory;
  }
}

export interface ProductQuantityDTO extends ListingDTO { }

export class ProductQuantity extends Listing {
  constructor(dto: ProductQuantityDTO) {
    super(dto);
  }

  toDTO(): ProductQuantityDTO {
    return <ProductQuantityDTO>super.toDTO();
  }
}

export interface ProductWeightDTO extends ListingDTO { }

export class ProductWeight extends Listing {
  constructor(dto: ProductWeightDTO) {
    super(dto);
  }

  toDTO(): ProductWeightDTO {
    return <ProductWeightDTO>super.toDTO();
  }
}

export interface ProfessionalSkillDTO extends ListingDTO { }

export class ProfessionalSkill extends Listing {
  constructor(dto: ProfessionalSkillDTO) {
    super(dto);
  }

  toDTO(): ProfessionalSkillDTO {
    return <ProfessionalSkillDTO>super.toDTO();
  }
}

export interface ProfessionDTO extends ListingDTO { }

export class Profession extends Listing {
  constructor(dto: ProfessionDTO) {
    super(dto);
  }

  toDTO(): ProfessionDTO {
    return <ProfessionDTO>super.toDTO();
  }
}

export interface QualificationDTO extends ListingDTO { }

export class Qualification extends Listing {
  constructor(dto: QualificationDTO) {
    super(dto);
  }

  toDTO(): QualificationDTO {
    return <QualificationDTO>super.toDTO();
  }
}

export interface CountryDTO extends ListingDTO {
  is_italy: boolean;
}

export class Country extends Listing {
  is_italy: boolean;
  constructor(dto: CountryDTO) {
    super(dto);
    if (dto) {
      this.is_italy = dto.is_italy;
    }
  }

  toDTO(): CountryDTO {
    let dto: CountryDTO = <CountryDTO>super.toDTO();
    dto.is_italy = this.is_italy;
    return dto;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Country) {
    const formModel = formGroup.value;
    let country: Country = new Country(null);
    country.value = formModel.value;
    country.is_italy = formModel.is_italy;
    if (original) {
      country.id = original.id;
    }
    return country;
  }
}

export interface RegionDTO extends ListingDTO {
  country_id: number;
  country: CountryDTO;
 }

export class Region extends Listing {
  country: Country;
  constructor(dto: RegionDTO) {
    super(dto);
    if(dto) {
      if (dto.country) {
        this.country = new Country(dto.country);
        this.addLoadedRelation("country");
      }
    }
  }

  toDTO(): RegionDTO {
    let dto: RegionDTO = <RegionDTO>super.toDTO();
    dto.country = this.country ? this.country.toDTO() : null;
    dto.country_id = this.country ? this.country.id : null;
    return dto;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Region) {
    const formModel = formGroup.value;
    let region: Region = new Region(null);
    region.value = formModel.value;
    if(formModel.country) {
      region.country = formModel.country;
    }
    if (original) {
      region.id = original.id;
    }
    return region;
  }
}

export interface SpecializationDTO extends ListingDTO {
  profession_id: number;
  profession: ProfessionDTO;
}

export class Specialization extends Listing {
  profession: Profession;
  constructor(dto: SpecializationDTO) {
    super(dto);
    if (dto) {
      if (dto.profession) {
        this.profession = new Profession(dto.profession);
        this.addLoadedRelation("profession");
      }
    }
  }

  toDTO(): SpecializationDTO {
    let dto: SpecializationDTO = <SpecializationDTO>super.toDTO();
    dto.profession = this.profession ? this.profession.toDTO() : null;
    dto.profession_id = this.profession ? this.profession.id : null;
    return dto;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Specialization) {
    const formModel = formGroup.value;
    let specialization: Specialization = new Specialization(null);
    specialization.value = formModel.value;
    specialization.profession = formModel.profession;
    if (original) {
      specialization.id = original.id;
    }
    return specialization;
  }
}

export interface ProvinceDTO extends ListingDTO {
  region_id: number;
  region: RegionDTO;
}

export class Province extends Listing {
  region: Region;
  constructor(dto: ProvinceDTO) {
    super(dto);
    if (dto) {
      if (dto.region) {
        this.region = new Region(dto.region);
        this.addLoadedRelation("region");
      }
    }
  }

  toDTO(): ProvinceDTO {
    let dto: ProvinceDTO = <ProvinceDTO>super.toDTO();
    dto.region = this.region ? this.region.toDTO() : null;
    dto.region_id = this.region ? this.region.id : null;
    return dto;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Province) {
    const formModel = formGroup.value;
    let province: Province = new Province(null);
    province.value = formModel.value;
    province.region = formModel.region;
    if (original) {
      province.id = original.id;
    }
    return province;
  }
}

export interface TreatedPatologyDTO extends ListingDTO { }

export class TreatedPatology extends Listing {
  constructor(dto: TreatedPatologyDTO) {
    super(dto);
  }

  toDTO(): TreatedPatologyDTO {
    return <TreatedPatologyDTO>super.toDTO();
  }
}

export interface UserCategoryDTO extends ListingDTO { }

export class UserCategory extends Listing {
  constructor(dto: UserCategoryDTO) {
    super(dto);
  }

  toDTO(): UserCategoryDTO {
    return <UserCategoryDTO>super.toDTO();
  }
}

//Iscrizione a Pegaso
export interface MasterTitleDTO extends ListingDTO { }

export class MasterTitle extends Listing {
  constructor(dto: MasterTitleDTO) {
    super(dto);
  }

  toDTO(): MasterTitleDTO {
    return <MasterTitleDTO>super.toDTO();
  }
}

export interface ECPDTO extends ListingDTO { }

export class ECP extends Listing {
  constructor(dto: ECPDTO) {
    super(dto);
  }

  toDTO(): ECPDTO {
    return <ECPDTO>super.toDTO();
  }
}

export interface AgreementDTO extends ListingDTO { }

export class Agreement extends Listing {
  constructor(dto: AgreementDTO) {
    super(dto);
  }

  toDTO(): AgreementDTO {
    return <AgreementDTO>super.toDTO();
  }
}

export interface PaymentTypeDTO extends ListingDTO { }

export class PaymentType extends Listing {
  constructor(dto: PaymentTypeDTO) {
    super(dto);
  }

  toDTO(): PaymentTypeDTO {
    return <PaymentTypeDTO>super.toDTO();
  }
}

export interface ConventionCategoryDTO extends ListingDTO { }

export class ConventionCategory extends Listing {
  constructor(dto: ConventionCategoryDTO) {
    super(dto);
  }

  toDTO(): ConventionCategoryDTO {
    return <ConventionCategoryDTO>super.toDTO();
  }
}

export interface CertificateTypeDTO extends BaseDTO {
  name: string;
  description: string;
  duration: number;
}

export class CertificateType extends Base {
  name: string;
  description: string;
  duration: number;
  constructor(dto: CertificateTypeDTO) {
    super(dto);
    if (dto) {
      this.description = dto.description;
      this.duration = dto.duration;
      this.name = dto.name;
    }
  }

  toDTO(): CertificateTypeDTO {
    let dto = <CertificateTypeDTO>super.toDTO();
    dto.name = this.name;
    dto.description = this.description;
    dto.duration = this.duration;
    return dto;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: CertificateType) {
    const formModel = formGroup.value;
    let certificateType: CertificateType = new CertificateType(null);
    certificateType.name = formModel.name;
    certificateType.description = formModel.description;
    certificateType.duration = formModel.duration;
    if (original) {
      certificateType.id = original.id;
    }
    return certificateType;
  }
}
